import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_biomed_texture.jpg';
import '../../../styles/pages/_unit-sections.scss';

const BiomedUnit2 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 2',
    linkLabel: 'BIOMED COURSE',
    to: '/classroom-instruction/biomed',
  };

  const {
    bmCurrentInfectiousDiseases,
    bmActionCommunityIntro,
    bmEpidemiology,
    bmTreatingBacterialViralDisease,
    bmNewProductionMethods,
    bmManufacturingMedicine,
    bmElizaVaccineTrials,
    bmVaccineProductionSafetyTesting,
    bmPublicHealthAgencies,
    bmSocialAwarenessCampaignProject,
  } = getKeyedResources(data);

  return (
    <Layout title="Biomed Unit 2">
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-biomed-unit2.jpg"
        guidSrc="6869163e-ae28-4109-89c0-20949aebdd67"
        posterSrc={'unit-2-biomed-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'orange'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">
          Taking Action in Your Community: Health Equity
        </h1>
        <p className="pb-2">
          How can biotechnology be successfully incorporated into a community?
        </p>
      </Hero>
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>BIOMED | UNIT 2</div>
            <Link
              to="/classroom-instruction/biomed/unit-3"
              className="course__unit-indicator-link"
            >
              UNIT 3 <Icon marginLeft name="arrowright" />
            </Link>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Discover how pandemics are mitigated, then determine ways to
                  address disparities and access to healthcare.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>¼ School Year</li>
                <li>45 Days</li>
                <li>6 Flex Days Built In</li>
                <li>45-minute Classes</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Guiding Question</li>
                <li>Engineering Design Process</li>
                <li>Project-based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>8 Lessons with Lab Elements</li>
                <li>1 Core Lab </li>
                <li>1 Final Project </li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, a student interactive,
                and student capture sheets that empower educators to engage all
                students. This lesson bundle can be downloaded for use in the
                classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-BM-U2.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 13.2 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        <Container fullWidth>
          <Row>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmActionCommunityIntro.tophat}
                tophatClass={`${bmActionCommunityIntro.page}__resource-tophat`}
                ribbon={bmActionCommunityIntro.ribbon}
                ribbonClass={`${bmActionCommunityIntro.page}__resource-ribbon`}
                img=""
                duration={bmActionCommunityIntro.duration}
                subject={bmActionCommunityIntro.subject}
                subjectClass={`${bmActionCommunityIntro.page}__resource-subject`}
                title={bmActionCommunityIntro.title}
                description={bmActionCommunityIntro.description}
                actions={bmActionCommunityIntro.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmEpidemiology.tophat}
                tophatClass={`${bmEpidemiology.page}__resource-tophat`}
                ribbon={bmEpidemiology.ribbon}
                ribbonClass={`${bmEpidemiology.page}__resource-ribbon`}
                img=""
                duration={bmEpidemiology.duration}
                subject={bmEpidemiology.subject}
                subjectClass={`${bmEpidemiology.page}__resource-subject`}
                title={bmEpidemiology.title}
                description={bmEpidemiology.description}
                actions={bmEpidemiology.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmCurrentInfectiousDiseases.tophat}
                tophatClass={`${bmCurrentInfectiousDiseases.page}__resource-tophat`}
                ribbon={bmCurrentInfectiousDiseases.ribbon}
                ribbonClass={`${bmCurrentInfectiousDiseases.page}__resource-ribbon`}
                img=""
                duration={bmCurrentInfectiousDiseases.duration}
                subject={bmCurrentInfectiousDiseases.subject}
                subjectClass={`${bmCurrentInfectiousDiseases.page}__resource-subject`}
                title={bmCurrentInfectiousDiseases.title}
                description={bmCurrentInfectiousDiseases.description}
                actions={bmCurrentInfectiousDiseases.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmTreatingBacterialViralDisease.tophat}
                tophatClass={`${bmTreatingBacterialViralDisease.page}__resource-tophat`}
                ribbon={bmTreatingBacterialViralDisease.ribbon}
                ribbonClass={`${bmTreatingBacterialViralDisease.page}__resource-ribbon`}
                img=""
                duration={bmTreatingBacterialViralDisease.duration}
                subject={bmTreatingBacterialViralDisease.subject}
                subjectClass={`${bmTreatingBacterialViralDisease.page}__resource-subject`}
                title={bmTreatingBacterialViralDisease.title}
                description={bmTreatingBacterialViralDisease.description}
                actions={bmTreatingBacterialViralDisease.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmNewProductionMethods.tophat}
                tophatClass={`${bmNewProductionMethods.page}__resource-tophat`}
                ribbon={bmNewProductionMethods.ribbon}
                ribbonClass={`${bmNewProductionMethods.page}__resource-ribbon`}
                img=""
                duration={bmNewProductionMethods.duration}
                subject={bmNewProductionMethods.subject}
                subjectClass={`${bmNewProductionMethods.page}__resource-subject`}
                title={bmNewProductionMethods.title}
                description={bmNewProductionMethods.description}
                actions={bmNewProductionMethods.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmManufacturingMedicine.tophat}
                tophatClass={`${bmManufacturingMedicine.page}__resource-tophat`}
                ribbon={bmManufacturingMedicine.ribbon}
                ribbonClass={`${bmManufacturingMedicine.page}__resource-ribbon`}
                img=""
                duration={bmManufacturingMedicine.duration}
                subject={bmManufacturingMedicine.subject}
                subjectClass={`${bmManufacturingMedicine.page}__resource-subject`}
                title={bmManufacturingMedicine.title}
                description={bmManufacturingMedicine.description}
                actions={bmManufacturingMedicine.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmElizaVaccineTrials.tophat}
                tophatClass={`${bmElizaVaccineTrials.page}__resource-tophat`}
                ribbon={bmElizaVaccineTrials.ribbon}
                ribbonClass={`${bmElizaVaccineTrials.page}__resource-ribbon`}
                img=""
                duration={bmElizaVaccineTrials.duration}
                subject={bmElizaVaccineTrials.subject}
                subjectClass={`${bmElizaVaccineTrials.page}__resource-subject`}
                title={bmElizaVaccineTrials.title}
                description={bmElizaVaccineTrials.description}
                actions={bmElizaVaccineTrials.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmVaccineProductionSafetyTesting.tophat}
                tophatClass={`${bmVaccineProductionSafetyTesting.page}__resource-tophat`}
                ribbon={bmVaccineProductionSafetyTesting.ribbon}
                ribbonClass={`${bmVaccineProductionSafetyTesting.page}__resource-ribbon`}
                img=""
                duration={bmVaccineProductionSafetyTesting.duration}
                subject={bmVaccineProductionSafetyTesting.subject}
                subjectClass={`${bmVaccineProductionSafetyTesting.page}__resource-subject`}
                title={bmVaccineProductionSafetyTesting.title}
                description={bmVaccineProductionSafetyTesting.description}
                actions={bmVaccineProductionSafetyTesting.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmPublicHealthAgencies.tophat}
                tophatClass={`${bmPublicHealthAgencies.page}__resource-tophat`}
                ribbon={bmPublicHealthAgencies.ribbon}
                ribbonClass={`${bmPublicHealthAgencies.page}__resource-ribbon`}
                img=""
                duration={bmPublicHealthAgencies.duration}
                subject={bmPublicHealthAgencies.subject}
                subjectClass={`${bmPublicHealthAgencies.page}__resource-subject`}
                title={bmPublicHealthAgencies.title}
                description={bmPublicHealthAgencies.description}
                actions={bmPublicHealthAgencies.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmSocialAwarenessCampaignProject.tophat}
                tophatClass={`${bmSocialAwarenessCampaignProject.page}__resource-tophat`}
                ribbon={bmSocialAwarenessCampaignProject.ribbon}
                ribbonClass={`${bmSocialAwarenessCampaignProject.page}__resource-ribbon`}
                img=""
                duration={bmSocialAwarenessCampaignProject.duration}
                subject={bmSocialAwarenessCampaignProject.subject}
                subjectClass={`${bmSocialAwarenessCampaignProject.page}__resource-subject`}
                title={bmSocialAwarenessCampaignProject.title}
                description={bmSocialAwarenessCampaignProject.description}
                actions={bmSocialAwarenessCampaignProject.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 2',
            link: '/classroom-instruction/biomed/unit-2',
          }}
          next={{
            name: 'Unit 3',
            link: '/classroom-instruction/biomed/unit-3',
          }}
          previous={{
            name: 'Unit 1',
            link: '/classroom-instruction/biomed/unit-1',
          }}
        />
      </Section>
    </Layout>
  );
};

export default BiomedUnit2;

export const query = graphql`
  query BiomedUnit2Resources {
    allResourcesJson(filter: { page: { eq: "biomed" }, unit: { eq: 2 } }) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
